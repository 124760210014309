.notification-item {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    padding: 16px;
    position: relative;
}

.notification-item-unread-dot {
    background-color: #ff3e3e;
    border-radius: 50%;
    height: 8px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 8px;
}

.notification-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.notification-item-header-title {
    font-size: 16px;
    font-weight: 600;
}

.notification-item-header-other {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notification-item-header-other-time {
    color: #888888;
    font-size: 12px;
    margin-right: 8px;
}

.notification-item-header-other-explorer-link {
    display: flex;
    align-items: center;
}

.notification-item-header-other-explorer-link a {
    color: #007bff;
    font-size: 12px;
    text-decoration: none;
}

.notification-item-header-other-explorer-link a:hover {
    text-decoration: underline;
}

.notification-item-body {
    color: #333333;
    font-size: 14px;
}

.load-more-text {
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
}

.load-more-text:hover {
    text-decoration: underline;
}
