.unread-count-in-bell {
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 3px 6px;
  width: 24px;
  height: 24px;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
