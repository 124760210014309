.mark-all-read {
  background-color: #0077b6;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 8px 16px;
  transition: background-color 0.2s ease-in-out;
}

.mark-all-read:hover {
  background-color: #0056a6;
}

.mark-all-read:active {
  background-color: #00448c;
}

.mark-all-read:focus {
  outline: none;
}

.mark-all-read[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}
